/* jshint esversion: 6 */

// Init jQuery
require("jquery");
require('slick-carousel');
import LazyLoad from "vanilla-lazyload";

//One offs
// Lazyload images
let myLazyLoad = new LazyLoad();

// Import the 'Application' library
let Application = require('./lib/application');
let previousPosition = window.scrollY || document.documentElement.scrollTop;
let media = getMedia();

// Init our project
$( function () {

  // Create an instance of the Application library with our custom configuration.
  window.app = new Application( function ( self ) {
    self.controllers = {
      'reveal': require('./classes/reveal'),
      'parallax': require('./classes/parallax'),
      'scroll_tween': require('./classes/scroll_tween'),
      'type_filter': require('./classes/type_filter')
    };
  });

  // on resize
  window.onresize = function(event) { media = getMedia(); };

  // clone nav and
  let $nav = document.querySelector('.nav-bar');
  let $navClone = $nav.cloneNode(true);
  $navClone.classList.add('js-clone');
  $navClone.classList.add('fixed');
  $nav.parentNode.insertBefore($navClone, $nav.nextSibling);

  // on scroll
  window.addEventListener('scroll', function() {

    let height = $navClone.offsetHeight;
    let currentPosition = window.scrollY || document.documentElement.scrollTop;

    if (currentPosition > height) {
      if (previousPosition > currentPosition) {
        $navClone.classList.add('scroll-up');
        $navClone.classList.remove('scroll-down');
      } else {
        $navClone.classList.add('scroll-down');
        $navClone.classList.remove('scroll-up');
      }
    } else {
      $navClone.classList.remove('scroll-up');
      $navClone.classList.remove('scroll-down');
    }

    previousPosition = currentPosition;
  });

  /* ==============================================
   *  Extras
   * =========================================== */

  // timeline
  $('.timeline .bar a').click( function (event) {
    event.preventDefault();

    let $this = $(this);
    let index = ($this.index() + 1);

    // progress bar
    let progress = $this.position().left + ($this.outerWidth() / 2);
    $('.progress').css('width', progress+'px');

    // change state of dots
    $this.siblings().removeClass('past active');
    $this.prevAll().addBack().addClass('past');
    $this.addClass('active');

    // update details
    let details = $('.details [data-year="'+String($this.data('year'))+'"]');
    details.siblings().toggleClass('active', false)
    details.toggleClass('active', true)

  });

  $('.bar a:first-child').click();

  $('.timeline .years .prev').click( function (event) {
    event.preventDefault();

    let $active = $('.bar .active');
    let perPage = media == 'desktop' ? 5 : 3;
    let index = ($active.index() - 1);
    let page = ( Math.floor(index / perPage) * 100 );

    $active.prev().click();

    // move timeline
    $('.wrapper').css({
      '-webkit-transform' : 'translateX(-'+page+'%)',
      '-moz-transform'    : 'translateX(-'+page+'%)',
      '-ms-transform'     : 'translateX(-'+page+'%)',
      '-o-transform'      : 'translateX(-'+page+'%)',
      'transform'         : 'translateX(-'+page+'%)'
    });

  });

  $('.timeline .years .next').click( function (event) {
    event.preventDefault();

    let $active = $('.bar .active');
    let $next = $active.next('[data-year]');

    if ($next.length) {

      let perPage = media == 'desktop' ? 5 : 3;
      let index = ($active.index() + 1);
      let page = ( Math.floor(index / perPage) * 100 );

      $active.next().click();

      // move timeline
      $('.wrapper').css({
        '-webkit-transform' : 'translateX(-'+page+'%)',
        '-moz-transform'    : 'translateX(-'+page+'%)',
        '-ms-transform'     : 'translateX(-'+page+'%)',
        '-o-transform'      : 'translateX(-'+page+'%)',
        'transform'         : 'translateX(-'+page+'%)'
      });

    }

  });

  // team bios
  $('.team-featured a, .team-list a').click( function (event) {
    // $('body').toggleClass('no-scroll');
    let $body = $('body');

    if ($body.hasClass('no-scroll')) {
      $body.removeClass('no-scroll');
      stopBodyScrolling(false);
    } else {
      $body.addClass('no-scroll');
      stopBodyScrolling(true);
    }
    
  });

  function stopBodyScrolling (bool) {
    if (bool === true) {
      document.body.addEventListener("touchmove", freezeVp, false);
    } else {
      document.body.removeEventListener("touchmove", freezeVp, false);
    }
  }

  let freezeVp = function(e) { e.preventDefault(); };

  // lightgallery
  $('[data-lightgallery]').lightGallery({
    zoom: true,
    loop: true,
    thumbnail: false,
    share: false,
    autoplayControls: false,
    fullScreen: false,
    controls: true,
    pager: true,
    selector: 'a'
  });

  // slick
  $('[data-slick]').slick();

  // Select all links with hashes
  $('[data-smooth-scroll]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        let target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 1000, function() {
            // Callback after animation
            // Must change focus!
            let $target = $(target);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            };
          });
        }
      }
    });

  $('[href^="mailto:"]').each( function() {
    let $this = $(this);
    let match = $this.attr('href').match('^mailto:(.*)\=$');
    if ( match && match.length >= 2 ) {
      let mailto = atob(match[1]);
      $this.attr( 'href', 'mailto:' + mailto );
      $this.html( mailto );
    }
  });

});

function getMedia() { return (window.matchMedia("(min-width: 960px)").matches ? 'desktop' : 'mobile'); }