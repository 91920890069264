class ScrollTween {

  constructor(app, $element) {
    this.minWidth = 960;
    this.dataSelector = 'tween';
    this.placeholder = '[%]';
    this.objects = $element[0].querySelectorAll('[data-'+this.dataSelector+']');

    for ( var o = 0; o < this.objects.length; o++ ) {
      var obj = this.objects[o];
      if ( obj.hasAttribute("style") ) {
        obj.dataset.style_restore = obj.getAttribute('style');
      }
    }

    this.tick();
  }

  scrollProgress( start = 0, end = 1) {
    var scrollY = ( window.scrollY || document.scrollTop ) - ( document.clientTop || 0 );
    var anim_duration = document.body.offsetHeight - window.innerHeight;

    // it starts as NaN :S this is a quick fix...
    scrollY = isNaN(scrollY) ? 0 : scrollY;

    // Adjust progress based on start & end inputs.
    scrollY -= (anim_duration * start);
    anim_duration *= (end - start);

    var anim_position = (100 / anim_duration) * scrollY;

    return ( isNaN(anim_position) ? 0 : anim_position ) / 100;
  }

  doTween ( from = 0, to = 100, progress = null ) {
    progress = progress !== null ? progress : scrollProgress();
    return ((to - from) * progress) + from;
  }

  tick( tock ) {

    if ( this.objects.length ) {

      for ( var o = 0; o < this.objects.length; o++ ) {
        var obj = this.objects[o];
        var args = JSON.parse( obj.getAttribute('data-' + this.dataSelector ) );

        // Responsive(-ish)
        if ( window.innerWidth >= this.minWidth ) {

          for ( var key in args ) {
            var tween = args[ key ];
                tween.units = ( typeof tween.units !== "undefined" ? tween.units : "%" );
                tween.from = ( typeof tween.from !== "undefined" ? tween.from : 0 );
                tween.to = ( typeof tween.to !== "undefined" ? tween.to : 100 );
                tween.start = ( typeof tween.start !== "undefined" ? tween.start : 0 );
                tween.end = ( typeof tween.end !== "undefined" ? tween.end : 1 );
                tween.template = ( typeof tween.template !== "undefined" ? tween.template : this.placeholder );

            var progress = this.scrollProgress( tween.start, tween.end );
            var setValue = this.doTween( tween.from, tween.to, progress );

            if ( tween.units !== "px" && setValue < 0 ) {
              setValue = 0;
            }

            obj.style[ key ] = tween.template.split( this.placeholder ).join( setValue + tween.units );
          }

        } else if ( obj.hasAttribute("style") ) {
          if ( typeof obj.dataset.style_restore !== "undefined" ) {
            obj.setAttribute("style", obj.dataset.style_restore );
          } else {
            obj.removeAttribute("style");
          }
        }
      }
    }

    window.requestAnimationFrame( this.tick.bind( this ) );
  }
}

module.exports = ScrollTween;
