module.exports = class {

  constructor(app, $element) {

    this.minWidth = this.minWidth ? this.minWidth : 960;
    this.$win = $(window);
    this.top;
    this.height;

    if ( this.$win.innerWidth() >= this.minWidth ) {
      window.addEventListener('scroll', this.scrollElem.bind( this ));
    }

  }

  scrollElem() {
    
    var $elem = this.$element[0];
    this.top = $elem.getBoundingClientRect().top;
    this.height = $elem.offsetHeight;
    
    if (this.top >= 0) var pos = Math.max(0, Math.min(100, (window.innerHeight - this.top) * 100 / this.height));
    else var pos = Math.max(0, Math.min(100, (this.height + this.top) * 100 / this.height));
    
    var percentage = (60 + (pos / 10)).toFixed(2);
    $elem.style.width = percentage+"%";

  }

};
