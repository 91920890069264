module.exports = class {

  constructor(app, $element) {

    this.minWidth = this.minWidth ? this.minWidth : 960;
    this.$win = $(window);
    this.toggleClass = 'reveal';
    this.paddReveal = 0.75;
    this.animated = false;
    // this.initReveal = false;
    this.top;
    this.height;

    if ( this.$win.innerWidth() >= this.minWidth ) {
      if ($element[0].dataset.initReveal) {
        this.animIn($element);
      } else {
        window.addEventListener('scroll', this.isInView.bind( this ));
      }
    }

  }

  isInView() {
    var scrollY = window.scrollY ? window.scrollY : document.documentElement.scrollTop;
    var viewBottom = scrollY + window.innerHeight * this.paddReveal;

    var $elem = this.$element[0];
    // this.top = $elem.getBoundingClientRect().top;
    this.top = this.getPosition($elem).y;
    this.height = $elem.offsetHeight;

    if ( viewBottom > ( this.top + this.height ) ) {
      $elem.classList.add( this.toggleClass );

      if (!this.animated) {
        this.animated = true;
        $elem.classList.contains('heading') || $elem.classList.contains('heading-quote') || $elem.classList.contains('heading-small') || $elem.classList.contains('heading-smaller') || $elem.classList.contains('heading-sub') ? this.animIn(this.$element) : this.fadeIn(this.$element);
      }

    } else {
      $elem.classList.remove(this.toggleClass);
      this.animated = false;
    }

  }

  animIn($element) {

    var $el = $($element[0]).first();
    var text = $.trim($el.text()),
        words = text.split(" "), html = "";

    for (var i = 0; i < words.length; i++) {
      html += "<span>" + words[i] + ((i+1) === words.length ? "" : " ") + "</span>";
    };

    $el.html(html).children().each(function( index ) {
      var $this = $(this);
      var i = 0;

      var timer = setInterval(function(){
        if (i == words.length) clearInterval(timer);
        $this.addClass('show');
        i++;
      }, (index + 1) * 30);

    });

  }

  fadeIn($element) {}

  getPosition(el) {

    var x = 0, y = 0;

    while (el != null && (el.tagName || '').toLowerCase() != 'html') {
      x += el.offsetLeft || 0;
      y += el.offsetTop || 0;
      el = el.parentElement;
    }

    return { x: parseInt(x, 10), y: parseInt(y, 10) };
  }

};
