module.exports = function (constructor) {
  var self = this;
      self.$window = $(window);
      self.$body = $('body')
      self.controllers = {};
      self.templates = {};

  function digest ( controller ) {
    controller.$element.find('[data-reflect]').each( function () {
      var $reflector = $(this);
      $reflector.html( apply_context( controller, function () {
        return eval( $reflector.data('reflect') );
      }));
    });
  }

  function apply_context ( ctx, target, args ) {
    args = ( typeof args !== "undefined" ? ( Array.isArray( args ) ? args : [ args ] ) : [] );
    return target.apply( ctx, args );
  }

  self.loading = function ( $target, loading ) {
    $target.toggleClass( 'js-loading', ( typeof loading === "undefined" ? true : loading ) );
  };

  self.bind_controllers = function ( $targets ) {

    if ( $targets.length ) {

      $targets.each(function () {
        var $this = $(this);

        if ( typeof self.controllers[ $this.data('controller') ] !== "undefined" ) {
          var controller = new self.controllers[ $this.data('controller') ]( self, $this );

          if ( typeof controller.data === 'undefined' ) {
            controller.data = {};
          }

          $.extend( controller.data, $this.data() );

          if ( typeof controller.init !== 'undefined' ) {
            controller.init(self, $this);
          }

          for ( var key in $this.data() ) {
            if ( typeof controller[ key ] !== "undefined" ) {
              controller[ key ] = $this.data( key );
            }
          }

          controller.app = self;
          controller.$element = $this;

          $this.data('controller', controller);
        }
      });
    }
  };

  self.bind_events = function ( $targets ) {

    if ( $targets.length ) {

      $targets.each(function () {
        var $this = $(this);
        var bind = $this.data('bind').match(/^([^:]*):([^\(]*)\(?([^\)]*)?\)?$/);

        if ( bind ) {

          $this.on( bind[1], function (event) {
            var target = self;
            var $controller = $this.closest('[data-controller]');

            // bubble up the controler hierarchy and attempt to resolve this bind action.
            while ( $controller.length ) {
              var controller = $controller.data('controller');

              $controller = $controller.parent();

              // We found a controller with this action, lets run it.
              if ( typeof controller[bind[2]] === "function" ) {
                var args = [event, $this, controller];
                if ( bind[3] ) { args = args.concat( bind[3].split(',') ); }
                return controller[ bind[2] ].apply(controller, args);
              }

              // no hit yet, keep moving up the hierarchy.
              $controller = $controller.closest('[data-controller]');
            }

            // we found nothing, lets try to resolve this with the app itself
            if ( typeof self[bind[2]] === "function" ) {
              var args = [event, $this];
              if ( bind[3] ) { args = args.concat( bind[3].split(',') ); }
              return self[ bind[2] ].apply(self, args);
            }

            // couldn't resolve it, give up.
            return null;
          });
        }
      });
    }
  }

  $('[type="text/template"]').each(function () {
    var $this = $(this);
    self.templates[ $this.attr('id') ] = $this.html();
    $this.remove();
  });

  self.bind_events( $('[data-bind]') );

  self.process_template = function ( html, data ) {
    for ( var key in data ) {
      var regex = new RegExp( '\{{2,}'+key+'\}{2,}', 'gi');
      html = html.replace( regex, data[key] );
    }
    return html;
  };

  self.render_template = function (template, data) {
    if ( self.templates[ template ] ) {
      return $( self.process_template( self.templates[ template ], data ) );
    }
  };

  self.bindClick = function ( objs, callback, preventDefault ) {
    if ( typeof objs === "string" ) { objs = $( objs ); }
    objs.on('click', function ( event ) {
      if ( event && preventDefault !== false ) { event.preventDefault(); }
      callback.apply( self, [ $(this), event ]);
    });
  };

  self.scrollTo = function( y, speed, focus, callback ) {
    speed = ( speed ? speed : 500 );
    var $target = null;
    var top = y;

    if ( typeof y !== "number" ) {
      $target = ( typeof y === "string" ? $(y) : y );
      top = $target.position().top;
    }

    $('html, body').animate({
      scrollTop: top
    }, speed, function() {

      if ( callback && typeof callback === "function" ) {
        callback( top, $target );
      }

      if ( $target && focus !== false ) {
        $target.focus();

        if ( $target.is(":focus") ) { // Checking if the target was focused
          return false;
        } else {
          $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
          $target.focus(); // Set focus again
        };
      }
    });
  }

  self.expand = function ( $target, force ) {
    force = ( typeof force === "undefined" ? $target.data('expand') : !force );
    $target.css('max-height', ( force ? '' : $target[0].scrollHeight ) );
    $target.data('expand', !force );
    return !force;
  };

  self.digest = function ( controller ) {
    if ( typeof controller['update'] === "function" ) {
      controller['update']( controller );
    }

    if ( typeof controller['digest'] === "function" ) {
      controller['digest']( controller );
    } else {
      digest( controller );
    }

    if ( typeof controller['updated'] === "function" ) {
      controller['updated']( controller );
    }
  };

  if ( typeof constructor === "function" ) {
    self = $.extend(self, constructor(self));
  }

  self.bind_controllers( $('[data-controller]') );

  self.validate_email = function ( email ) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  // self.resize.process_queries();
  // self.scroll.process_queries();

  return self;
};
