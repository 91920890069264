module.exports = class {

  constructor(app, $element) {
    this.app = app;
    this.$element = $element;
    this.items = [];
    this.filter_value = null;
    this.$links = $element.find('a[data-bind="click:filter"]');
    this.$select = $element.find('select[data-bind="change:filter"]');
    this.$paged = $element.find('[data-page]');
    this.perPage = $element.data('per-page') ? parseInt( $element.data('per-page') ) : 6;

    this.filter('current', null, this );
  }

  filter( event, $this, self ) {

    if ( typeof event === "object" ) {
      switch ( event.type ) {
        case "click":
          event.preventDefault();
          self.filter_value = $this.attr('href').replace('#', '');
        break;
        case "change":
          this.filter_value = $this.val();
        break;
      }
    } else {
      this.filter_value = event;
    }

    self.$links.toggleClass('active', false )
    self.$element.find('a[data-bind="click:filter"][href="#'+self.filter_value+'"]')
      .toggleClass('active', true );
    self.$select.val( self.filter_value );

    self.process();
  }

  process() {
    var self = this;

    // Make sure we have a filter value;
    if ( !self.filter_value ) {
      self.filter_value = "all";
    }

    // collect all the active items from the DOM
    self.$element.find('[data-filter-types]').each(function () {
      var $this = $(this);
      $this.toggleClass('display', false );
      self.items.push( $this );
      $this.remove();
    });

    for ( var i in self.items ) {
      var $this = self.items[i];
      if ( $this.data("filter-types").indexOf( self.filter_value ) > -1 ) {
        if ( $this.data("filter-types").indexOf( "current" ) > -1 ) {
          this.$paged.prepend( self.items[i] );
        } else {
          this.$paged.append( self.items[i] );
        }
      }
    }

    // reset the page to 1
    self.setPage( null, null, self, 1 );
  }

  setPage( event, $this, self, toPage ) {
    if (event) { event.preventDefault(); }

    var $filterable = self.$paged.find('[data-filter-types]');

    $this = self.$element.find('[data-bind="click:setPage"]');

    if ( self.$paged.length ) {
      var page = toPage ? toPage : ( parseInt( self.$paged.data('page') ) + 1 );

      self.$paged.data('page', page );
      self.$paged.attr('data-page', page );

      var animIn = setTimeout( function() {
        $filterable.each( function () {
          var $this = $(this);
          $this.toggleClass('display', true );
        });
      }, 100 );
    }

    $this.css('display',
      parseInt( self.$paged.data('page') ) * self.perPage < $filterable.length ?
      "block":
      "none"
    );

    // $this.css('display', self.$element.find('[data-filter-types]:not(:visible)').length ? "block" : "none" );
  }
}
